
import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useAuditListData';
import router from '@/router';
import useVisible from '@/hooks/useVisible';
import saveAuditOutPut from './components/saveAuditOutPut.vue';
import useTimer from '@/hooks/useTimer';
import { erpConfirm, errorMessage, exportFile } from '@/utils/publicMethods';
export default defineComponent({
  name: 'invoiceAuditList',
  components: { ErpList, saveAuditOutPut },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const data = reactive({
      rowData: null,
      outputAuditId: null,
      detailsData: null,
    });
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    // 返回
    const back = () => {
      router.push('/erp/financial/invoice');
    };
    const view = (row) => {
      data.outputAuditId = row.id;
      data.detailsData = row;
      showVisible();
    };
    // 查看详情
    const rowClick = () => {
      return true;
    };

    const { startTimer, timeStatus } = useTimer();
    // 筛选导出
    const exportFileByHead = () => {
      // 30秒内只能点击一次方法，
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/invoice/output/exportOutputHisList',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    return {
      ...toRefs(data),
      cancel,
      visible,
      showVisible,
      closeVisible,
      view,
      back,
      columnList,
      tableRef,
      refreshTable,
      rowClick,
      exportFileByHead,
    };
  },
});
