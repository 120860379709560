import { ErpTableOptions } from '@/types/type';
import { exportFile } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';

export default (Instance: any) => {
  const tableRef = ref();
  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '签约主体',
      prop: 'contractPrincipalName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '审核状态',
      prop: 'auditStatus',
      propDesc: 'auditStatusDesc',
      minWidth: 145,
      value: [],
      type: 'multiSelect',
      options: 'invoice_output_audit_status',
    },
    {
      label: '发票品类',
      prop: 'invoiceVariety',
      propDesc: 'invoiceVarietyDesc',
      minWidth: 145,
      value: '',
      type: 'signSelect',
      options: 'invoice_variety',
    },
    {
      label: '开票类型',
      prop: 'invoiceType',
      propDesc: 'invoiceTypeDesc',
      minWidth: 145,
      value: [],
      type: 'multiSelect',
      options: 'invoice_output_type',
    },
    {
      label: '本次开票金额',
      prop: 'totalAmount',
      propDesc: 'totalAmountDesc',
      minWidth: 160,
      type: 'number',
      value: [],
      filterProp: ['totalAmountMinVal', 'totalAmountMaxVal'],
    },
    {
      label: '已开服务发票金额',
      prop: 'serviceInvoicedAmount',
      propDesc: 'serviceInvoicedAmountDesc',
      minWidth: 160,
      type: 'number',
      value: [],
      filterProp: ['serviceInvoicedAmountMinVal', 'serviceInvoicedAmountMaxVal'],
      isFilter: false,
    },
    {
      label: '已开货物发票金额',
      prop: 'goodsInvoicedAmount',
      propDesc: 'goodsInvoicedAmountDesc',
      minWidth: 160,
      type: 'number',
      value: [],
      filterProp: ['goodsInvoicedAmountMinVal', 'goodsInvoicedAmountMaxVal'],
      isFilter: false,
    },
    {
      label: '税率',
      prop: 'taxRate',
      propDesc: 'taxRateDesc',
      minWidth: 120,
      value: '',
      type: 'signSelect',
      options: [
        {
          label: '6%',
          value: 6,
        },
        {
          label: '9%',
          value: 9,
        },
      ],
    },
    {
      label: '开票内容',
      prop: 'serviceInvoicedAmount',
      propDesc: 'serviceInvoicedAmountDesc',
      value: '',
      minWidth: 100,
      type: 'input',
      isFilter: false,
      isSort: false,
      component: defineComponent({
        template: `
        <span class="record__link" @click="view(row)">查看</span>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const view = (row) => {
            Instance.setupState.view(row);
          };
          return { view };
        },
      }),
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      propDesc: 'createdTime',
      minWidth: 160,
      value: [],
      type: 'time', // 筛选类型 time 日期
      filterProp: ['createdTimeStartDate', 'createdTimeEndDate'],
    },
    {
      label: '创建人',
      prop: 'creator',
      propDesc: 'creator',
      minWidth: 190,
      value: '',
      type: 'input',
    },
    {
      label: '审核时间',
      prop: 'auditTime',
      propDesc: 'auditTime',
      minWidth: 160,
      value: [],
      type: 'time', // 筛选类型 time 日期
      filterProp: ['auditTimeStartDate', 'auditTimeEndDate'],
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      propDesc: 'auditOperator',
      minWidth: 140,
      value: '',
      type: 'input',
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 88,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button type="primary" plain  v-if="hasPermission('erp:fmInfund:auditInfund')"
        @click='exportFileFn(row)'>导出</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const exportFileFn = (row) => {
            exportFile({
              type: 'POST',
              url: '/malicrm/invoice/output/exportOutputHisDetail',
              data: {
                id: row.id,
              },
            });
          };
          return { exportFileFn };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
